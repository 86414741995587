import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from 'yup';

import Flex from '../flex';
import Alert from '../alert';
import Input from '../input';
import Modal from '../modal';
import Loader from '../loader';
import Translate from '../translate';

import { sendFBEvent, sendGtagEvent, validateEmail } from '../../helpers';

import integrations from '../../configs/integrations';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';
import onboardUser from '../../utils/onboardUser';

import useDevice from '../../hooks/useDevice';
import useConnector from '../../hooks/useConnector';

const getProps = key => {
    switch (key) {
        case 'firstname':
            return ({
                autoComplete: key,
                placeholder: translate('Name'),
            });
        case 'company':
            return ({
                autoComplete: 'organization',
                placeholder: translate('Company Name'),
            });
        case 'email':
            return ({
                type: 'email',
                autoComplete: key,
                placeholder: translate('Email'),
                validation: Yup.string().email().required().test(validateEmail),
            });
        case 'mobilephone':
            return ({
                type: 'tel',
                autoComplete: key,
                placeholder: translate('Phone'),
            });
        default:
            return {};
    }
};

const StartAPartnershipModal = props => {
    const {
        active,
        onClose,
    } = props;

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [response, setResponse] = useState({
        show: false,
        type: '',
        text: '',
    });
    const [fields, setFields] = useState([]);
    const { data } = useConnector(`/contact-us-form/${integrations.hubSpot.forms.partnership}`, {});
    const { isMobile } = useDevice();

    useEffect(() => {
        if (data?.fieldGroups?.length) {
            const result = data.fieldGroups.map(group => group.fields).flat().reduce((acc, field) => ({
                fields: [...acc.fields, { ...field, ...getProps(field.name) }],
                initialValues: { ...acc.initialValues, [field.name]: '' },
                validationSchema: ({ ...acc.validationSchema, [field.name]: getProps(field.name)?.validation || (field.required ? Yup.string().required() : null) })
            }), { fields: [], initialValues: {}, validationSchema: {} });
            setFields(result.fields);
            setInitialValues(result.initialValues);
            setValidationSchema(result.validationSchema);
        }
    }, [data]);

    const handleOnSubmit = (values, { resetForm, setErrors }) => {
        setLoading(true);
        sendGtagEvent('conversion_event_submit_lead_form');
        sendFBEvent('Lead', { value: 1.00, currency: 'USD' });
        const url = `${integrations.hubSpot.api.endpoint}${integrations.hubSpot.portal}/${integrations.hubSpot.forms.partnership}`;
        axios.post(url, {
            submittedAt: new Date().getTime(),
            fields: [
                ...Object.keys(values).map(name => ({
                    name,
                    value: values[name],
                }))
            ],
            context: {
                pageUri: document.location.href,
                pageName: document.title,
            },
        }).then(() => {
            !!onClose && onClose();
            setResponse({
                show: true,
                type: 'success',
                text: <Translate>Thank you for your request. We'll be in touch as soon as possible!</Translate>,
            });
            resetForm();
            setLoading(false);
        }, () => {
            addToast({
                type: 'error',
                message: <Translate>Please recheck provided data.</Translate>,
            });
            setLoading(false);
        });
        onboardUser({
            name: 'Partnership',
            icon: ':handshake:',
            attachments: [
                {
                    type: 'section',
                    mrkdwn_in: ['text', 'footer'],
                    text: Object.keys(values).map(key => [(fields?.find(field => field?.name === key)?.label || key), '`' + (values[key] || 'none') + '`'].join(' - ')).join('\n'),
                },
            ],
        });
        setTimeout(() => {
            setErrors({});
        }, 0);
    };

    return (
        <>
            <Modal
                width={620}
                active={active}
                onClose={onClose}
                title={<Translate>Request Partnership</Translate>}
                primaryButton={{
                    loading,
                    hero: 'success',
                    children: <Translate>Send</Translate>,
                    onClick: () => {
                        !!formRef?.current?.handleSubmit && formRef?.current?.handleSubmit();
                    },
                }}
            >
                <Flex
                    column
                    className='position-relative'
                    minHeight={isMobile ? 268 : 124}
                >
                    {fields.length ? (
                        <Formik
                            innerRef={formRef}
                            validateOnBlur={false}
                            validateOnMount={false}
                            validateOnChange={false}
                            onSubmit={handleOnSubmit}
                            initialValues={initialValues}
                            validationSchema={Yup.object().shape(validationSchema)}
                        >
                            {({ errors, handleSubmit, }) => (
                                <form
                                    noValidate
                                    onSubmit={handleSubmit}
                                    className={classNames(
                                        `grid`,
                                        'gap-2',
                                        {
                                            'cols-2': !isMobile,
                                        }
                                    )}
                                >
                                    {fields.map(({ name, placeholder, autoComplete, required, type }, index) => (
                                        <Field
                                            name={name}
                                            key={name + index}
                                        >
                                            {({ field }) => (
                                                <Input
                                                    {...field}
                                                    required={required}
                                                    cornerRadius='full'
                                                    size='extra-big'
                                                    placeholder={placeholder}
                                                    autoComplete={autoComplete}
                                                    error={errors && errors[name]}
                                                    type={type === 'string' ? 'text' : type}
                                                />
                                            )}
                                        </Field>
                                    ))}
                                </form>
                            )}
                        </Formik>
                    ) : (
                        <Loader absolute />
                    )}
                </Flex>
            </Modal>
            <Alert
                text={response.text}
                type={response.type}
                show={response.show}
                onClose={() => setResponse(val => ({
                    ...val,
                    show: false,
                }))}
            />
        </>
    );
};

StartAPartnershipModal.propTypes = {
    active: PropTypes.bool,
    onClose: PropTypes.func,
};

export default StartAPartnershipModal;
