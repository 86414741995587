import PropTypes from 'prop-types';

import Button from '../button';
import AgenciesGraphics from '../agenciesGraphics';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const IntroForAdvertisersAndAgencies = props => {
    const {
        title,
        subTitle,
        primaryButton,
    } = props;

    const {
        isMobile,
    } = useDevice();

    return (
        <div className='intro-for-advertisers-holder'>
            <div className='intro-for-advertisers-content'>
                <h1>{title}</h1>
                <p>{subTitle}</p>
                <Button
                    color='bold'
                    flexibility='fit'
                    size={isMobile ? 'extra-big' : 'extra-huge'}
                    itemsDirection='end'
                    icon={{
                        type: 'icon-a-arrow-right',
                    }}
                    {...primaryButton}
                />
            </div>
            <AgenciesGraphics />
        </div>
    );
};

IntroForAdvertisersAndAgencies.propTypes = {
    title: PropTypes.node,
    subTitle: PropTypes.node,
    primaryButton: PropTypes.object,
};

export default IntroForAdvertisersAndAgencies;
