import { conditionalSpread, rem } from 'clyne-core';
import NumberFlowReact from '@number-flow/react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getCurrencySymbol, getIntlLocales } from '../../helpers';

import { preciseLocationState, userState, workspaceModeState } from '../../state';

import './index.scss';

const NumberFlow = props => {
    const {
        font,
        value,
        prefix,
        suffix,
        discount,
        price = true,
    } = props;

    const user = useRecoilValue(userState);
    const workspaceMode = useRecoilValue(workspaceModeState);
    const preciseLocation = useRecoilValue(preciseLocationState);

    const locales = getIntlLocales({
        user,
        preciseLocation,
    });

    const currencySymbol = getCurrencySymbol({
        user,
        workspaceMode,
        preciseLocation,
    });

    return (
        <div
            className={classNames(
                'number-flow-r-holder',
                {
                    discount,
                }
            )}
        >
            <NumberFlowReact
                locales={locales}
                value={isNaN(value) ? 0 : value}
                {...conditionalSpread({
                    prefix: prefix || `${currencySymbol?.symbol} `,
                }, (currencySymbol?.prefix && price) || !!prefix)}
                {...conditionalSpread({
                    suffix: [
                        (suffix || ` ${currencySymbol?.symbol}`),
                    ].filter(Boolean).join(' '),
                }, (currencySymbol?.suffix && price) || !!suffix)}
                style={{
                    ...conditionalSpread({
                        fontSize: rem(font?.size),
                    }, !!font?.size),
                    ...conditionalSpread({
                        fontWeight: font?.weight,
                    }, !!font?.weight),
                }}
            />
            {!!discount && (
                <div className='number-flow-discount'>
                    {`${discount}%`}
                </div>
            )}
        </div>
    );
};

NumberFlow.propTypes = {
    font: PropTypes.shape({
        size: PropTypes.number,
        weight: PropTypes.number,
    }),
    value: PropTypes.number,
    price: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    discount: PropTypes.number,
};

export default NumberFlow;
