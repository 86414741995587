import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Flex from '../flex';
import Button from '../button';
import MobileStack from '../mobileStack';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const PartnershipBenefits = props => {
    const {
        cta,
        title,
        cards,
    } = props;

    const {
        isMobile,
    } = useDevice();

    return (
        <Flex
            column
            gap={isMobile ? 30 : 40}
        >
            <div className='partnership-benefits-head'>
                <h2>
                    {title}
                </h2>
                <div className='partnership-hr t-blue' />
                <Button
                    color='bold'
                    flexibility='fit'
                    appearance='outline'
                    size={isMobile ? 'extra-big' : 'extra-huge'}
                    itemsDirection='end'
                    icon={{
                        type: 'icon-a-arrow-right',
                    }}
                    {...cta}
                />
            </div>
            <MobileStack>
                <div
                    className={classNames(
                        `grid`,
                        `cols-${cards.length}`,
                        {
                            'gap-2-4': !isMobile,
                        }
                    )}
                >
                    {cards.map((card, index) => (
                        <Flex
                            column
                            gap={12}
                            key={index}
                            stretch={false}
                            className='partnership-benefit-card'
                        >
                            {card.icon}
                            <h3>{card.title}</h3>
                            <p>{card.children}</p>
                        </Flex>
                    ))}
                </div>
            </MobileStack>
        </Flex>
    );
};

PartnershipBenefits.propTypes = {
    title: PropTypes.node,
    cta: PropTypes.object,
    cards: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.node,
        title: PropTypes.node,
        children: PropTypes.node,
    })),
};

export default PartnershipBenefits;
