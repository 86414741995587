import { AiMagicIcon, Dollar01Icon, Megaphone01Icon, Wallet01Icon } from '@hugeicons/react';
import React, { useState } from 'react';

import PartnershipCard from '../../components/partnershipCard';
//import OnBoardingGuide from '../../components/onBoardingGuide';
import PartnershipBenefits from '../../components/partnershipBenefits';
import PartnershipFeatures from '../../components/partnershipFeatures';
import PartnershipUpSaleRow from '../../components/partnershipUpSaleRow';
import StartAPartnershipModal from '../../components/startAPartnershipModal';
import IntroForAdvertisersAndAgencies from '../../components/introForAdvertisersAndAgencies';

import { iconSize } from '../../helpers';

import useInlineTranslation from '../../hooks/useInlineTranslation';

import '../../assets/scss/partnership.scss';

const ForHospitalities = () => {
    const [modalActive, setModalActive] = useState(false);

    const { getInlineTranslation } = useInlineTranslation();

    const cta = {
        onClick: () => setModalActive(true),
        children: getInlineTranslation({
            hy: 'Թողնել հայտ',
            ru: 'Остаивть заявку',
            en: 'Apply Now',
        }),
    };

    return (
        <>
            <div className='partnership-landing-holder'>
                <IntroForAdvertisersAndAgencies
                    title={getInlineTranslation({
                        en: (
                            <>
                                Your<br />
                                <strong>TV screens</strong><br />
                                as a <strong>new source</strong><br />
                                of income
                            </>
                        ),
                        ru: (
                            <>
                                Ваши<br />
                                <strong>ТВ-Экраны</strong><br />
                                - Новый<br />
                                <strong>Источник Дохода</strong>
                            </>
                        ),
                        hy: (
                            <>
                                Ձեր<br />
                                <strong>TV-էկրանները՝</strong><br />
                                Նոր<br /><strong>Եկամտի Աղբյուր</strong>
                            </>
                        ),
                    })}
                    subTitle={getInlineTranslation({
                        hy: 'Գրանցեք ձեր սրահում գտնվող էկրանները և ստացեք հավելյալ եկամուտ՝ առանց ավելորդ ժամանակ ծախսելու։',
                        ru: 'Просто зарегистрируйте экраны вашего заведения на нашей платформе и начните зарабатывать без лишних усилий и потери времени.',
                        en: 'Register the screens in your venue on our platform and earn extra income without spending additional time and resources.',
                    })}
                    primaryButton={cta}
                />
                {/*<OnBoardingGuide
                    cta={cta}
                    title={getInlineTranslation({
                        en: (
                            <>
                                Who Can<br/>
                                Become a Partner?
                            </>
                        ),
                        ru: (
                            <>
                                Кто может<br/>
                                стать партнером?
                            </>
                        ),
                        hy: (
                            <>
                                Ովքե՞ր կարող են<br/>
                                դառնալ գործընկեր
                            </>
                        ),
                    })}
                    slides={[
                        {
                            title: getInlineTranslation({
                                hy: 'Ժամանցի վայրեր',
                                en: 'Entertainment venues',
                                ru: 'Места для досуга',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Առևտրի կենտրոններ',
                                en: 'Shopping malls',
                                ru: 'Торговые центры',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Մարզասրահներ',
                                en: 'Gyms',
                                ru: 'Спортивные залы',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Գեղեցկության սրահներ',
                                en: 'Beauty salons',
                                ru: 'Салоны красоты',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Կլինիկաներ',
                                en: 'Clinics',
                                ru: 'Клиники',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Հանրային տարածքներ',
                                en: 'Other public spaces',
                                ru: 'Общественные пространства',
                            }),
                        },
                    ]}
                />*/}
                <PartnershipBenefits
                    title={getInlineTranslation({
                        en: (
                            <>
                                Why<br />
                                <strong>join</strong><br />
                                Advelit?
                            </>
                        ),
                        ru: (
                            <>
                                Почему<br />
                                <strong>присоединиться</strong><br />
                                к Advelit?
                            </>
                        ),
                        hy: (
                            <>
                                Ինչու՞<br />
                                <strong>միանալ</strong><br />
                                Ադվելիթին
                            </>
                        ),
                    })}
                    cta={cta}
                    cards={[
                        {
                            icon: (
                                <Wallet01Icon style={iconSize(36)} />
                            ),
                            title: getInlineTranslation({
                                en: (
                                    <>
                                        Minimal<br />
                                        Investment
                                    </>
                                ),
                                ru: (
                                    <>
                                        Минимальные<br />
                                        Вложения!
                                    </>
                                ),
                                hy: (
                                    <>
                                        Կատարե՛ք<br />
                                        Նվազագույն Ներդրումներ
                                    </>
                                ),
                            }),
                            children: getInlineTranslation({
                                ru: 'Станьте партнером Advelit — просто зарегистрируйте экраны, установленные в вашем заведении, на нашем сайте.',
                                en: 'Become an Advelit partner by simply registering the screens in your venue on advelit.com.',
                                hy: 'Դարձեք Advelit-ի գործընկերը՝ պարզապես գրանցելով կայքում ձեր սրահում տեղադրված էկրանները։',
                            }),
                        },
                        {
                            icon: (
                                <Dollar01Icon style={iconSize(36)} />
                            ),
                            title: getInlineTranslation({
                                en: (
                                    <>
                                        Earn<br />
                                        Revenue
                                    </>
                                ),
                                ru: (
                                    <>
                                        Получайте<br />
                                        Доход
                                    </>
                                ),
                                hy: (
                                    <>
                                        Ստացեք<br />
                                        Եկամուտ
                                    </>
                                ),
                            }),
                            children: getInlineTranslation({
                                ru: 'С помощью Advelit  будьте заметны для потенциальных рекламодателей, показывайте рекламу своим клиентам и получайте дополнительный доход.',
                                en: 'With Advelit, you’ll be visible to potential advertisers, allowing you to display ads to your customers and generate additional income.',
                                hy: 'Advelit-ի միջոցով դարձեք տեսանելի պոտենցիալ գովազդատուների համար, ցուցադրեք գովազդ ձեր հաճախորդներին և ստացեք հավելյալ եկամուտ։',
                            }),
                        },
                        {
                            icon: (
                                <AiMagicIcon style={iconSize(36)} />
                            ),
                            title: getInlineTranslation({
                                en: (
                                    <>
                                        Control<br />
                                        Screen Content
                                    </>
                                ),
                                ru: (
                                    <>
                                        Управляйте<br />
                                        Содержимым Экранов
                                    </>
                                ),
                                hy: (
                                    <>
                                        Վերահսկե՛ք<br />
                                        բովանդակությունը
                                    </>
                                ),
                            }),
                            children: getInlineTranslation({
                                ru: 'Как наш партнер, вы сами решаете и одобряете рекламу, которая будет отображаться на ваших экранах. Вы свободны в выборе рекламы, которая соответствует атмосфере вашего заведения и предпочтениям ваших клиентов.',
                                en: 'As our partner, you decide which ads are shown on your screens, selecting those that align with your venue’s ambiance and customer preferences.',
                                hy: 'Դուք, որպես մեր գործընկեր, ինքնե՛րդ եք որոշում ու հաստատում ձեր էկրաններին ցուցադրվող գովազդները։ Դուք ազատ եք ընտրելու այն գովազդը, որը համահունչ կլինի ձեր սրահի միջավայրին ու հաճախորդների նախասիրություններին։',
                            }),
                        },
                        {
                            icon: (
                                <Megaphone01Icon style={iconSize(36)} />
                            ),
                            title: getInlineTranslation({
                                en: (
                                    <>
                                        Exclusive Advertising<br />
                                        for Your Brand
                                    </>
                                ),
                                ru: (
                                    <>
                                        Эксклюзивная Реклама<br />
                                        Вашего Бренда
                                    </>
                                ),
                                hy: (
                                    <>
                                        Ձեր բրենդի բացառիկ<br />
                                        գովազդը մեր կայքում
                                    </>
                                ),
                            }),
                            children: getInlineTranslation({
                                ru: 'Повысьте видимость ваших рекламных экранов на сайте advelit.com и привлекайте новых клиентов, расширяя ваш охват и видимость для рекламодателей.',
                                en: 'Boost the visibility of your advertising screens on advelit.com and attract new clients by expanding your advertising space to potential advertisers.',
                                hy: 'Խթանեք ձեր գովազդային էկրանների տեսանելիությունը advelit.com կայքում ու ձեռք բերեք նոր հաճախորդներ՝ ընդլայնելով գովազդային վահանակների հասանելիությունը գովազդատուների համար։',
                            }),
                        },
                    ]}
                />
                <div className='partnership-hr t-green' />
                <PartnershipFeatures
                    title={getInlineTranslation({
                        en: (
                            <>
                                How does<br />
                                <strong>Advelit</strong> work?
                            </>
                        ),
                        ru: (
                            <>
                                Как<br />
                                работает <strong>Advelit?</strong>
                            </>
                        ),
                        hy: (
                            <>
                                Ինչպե՞ս է<br />
                                աշխատում <strong>Ադվելիթը</strong>
                            </>
                        ),
                    })}
                    cards={[
                        {
                            title: getInlineTranslation({
                                hy: 'Գրանցեք ձեր էկրանները կայքում',
                                en: 'Register Your Screens on the Website',
                                ru: 'Зарегистрируйте ваши экраны на сайте',
                            }),
                            children: getInlineTranslation({
                                hy: 'Պարզապես ավելացրեք ձեր սրահում գտնվող էկրանները advelit.com կայքում՝ հասանելի դարձնելով պոտենցիալ գովազդատուներին, ովքեր կտեսնեն, կընտրեն ու կամրագրեն դրանք։',
                                en: 'Simply add your venue’s screens to advelit.com to make them available to potential advertisers who can view, select, and book them.',
                                ru: 'Просто добавьте экраны, установленные в вашем заведении, на сайте advelit.com, делая их доступными для потенциальных рекламодателей, которые смогут увидеть, выбрать и забронировать их.',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Ստացեք գովազդային հայտեր',
                                en: 'Receive Advertising Requests',
                                ru: 'Получайте заявки на реклму',
                            }),
                            children: getInlineTranslation({
                                hy: 'Շնորհիվ advelit.com-ի ավտոմատացված գործիքների դուք մշտապես տեղյակ կլինեք գովազդատուների նոր հայտերի ու ամրագրումների մասին։',
                                en: 'Advelit.com’s automated tools keep you informed about new advertising requests and bookings.',
                                ru: 'Благодаря автоматизированным инструментам advelit.com вы всегда будете в курсе новых заявок и бронирований от рекламодателей.',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Հաստատեք գովազդները',
                                en: 'Approve Advertisements',
                                ru: 'Подтверждайте рекламу',
                            }),
                            children: getInlineTranslation({
                                hy: 'Դուք կարող եք լիովին կառավարել յուրաքանչյուր գովազդային հայտ՝ համոզվելու համար, որ այն համապատասխանում է ձեր բրենդին։',
                                en: 'You have full control over every ad request, ensuring it aligns with your brand.',
                                ru: 'Вы можете полностью управлять каждой рекламной заявкой, чтобы убедиться, что она соответствует вашему бренду.',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Ստացեք արդյունք՝ հավելյալ եկամտի տեսքով',
                                en: 'Earn Additional Income',
                                ru: 'Получайте результат в виде дополнительного дохода',
                            }),
                            children: getInlineTranslation({
                                hy: 'Յուրաքանչյուր ամսվա վերջում ստացեք եկամուտ՝ ձեր սրահում  ցուցադրված գովազդների դիմաց։',
                                en: 'At the end of each month, receive payments for the ads displayed at your venue.',
                                ru: 'В конце каждого месяца получите доход за рекламу, показанную в вашем заведении.',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Ազատվեք ավելորդ գլխացավանքից',
                                en: 'No Extra Hassle',
                                ru: 'Избавьтесь от лишних хлопот',
                            }),
                            children: getInlineTranslation({
                                hy: 'Advelit-ը  տրամադրում է գործիք գովազդային հայտեր ստանալու և էկրաններին ցուցադրելու տեխնիկական պրոցեսը իրականացնելու համար։',
                                en: 'Advelit provides tools to streamline the process of receiving ad requests and handling their technical display.',
                                ru: 'Advelit предоставляет инструменты для получения рекламных заявок и автоматизации технического процесса их отображения на экранах.',
                            }),
                        },
                        {
                            title: getInlineTranslation({
                                hy: 'Ստացեք հաշվետվություն',
                                en: 'Receive Reports',
                                ru: 'Получайте отчетность',
                            }),
                            children: getInlineTranslation({
                                hy: 'Advelit-ը  ձեզ կտրամադրի հաշվետվություն էկրանների աշխատանքի և ստացված եկամտի մասին։',
                                en: 'Advelit provides detailed reports on your screens’ performance and generated revenue.',
                                ru: 'Advelit предоставит вам отчетность о работе экранов и полученном доходе.',
                            }),
                        },
                    ]}
                />
                <PartnershipUpSaleRow
                    type='billboards'
                    cta={cta}
                />
                <div className='partnership-vice-versa-cards'>
                    {['billboards', 'agencies'].map(type => (
                        <PartnershipCard
                            key={type}
                            type={type}
                        />
                    ))}
                </div>
            </div>
            <StartAPartnershipModal
                active={modalActive}
                onClose={() => setModalActive(false)}
            />
        </>
    );
};

export default ForHospitalities;
