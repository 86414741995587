import { atom } from 'recoil';

import { getLSItem } from '../helpers';

import whiteLabel from '../constants/whiteLabel';

export const remoteActionState = atom({
    key: 'remoteActionState',
    default: {
        action: '',
        command: '',
    },
});

export const splashLoadersSate = atom({
    key: 'splashLoadersSate',
    default: [],
});

export const appStateChangedState = atom({
    key: 'appStateChangedState',
    default: 0,
});

export const crState = atom({
    key: 'crState',
    default: {
        footer: () => true,
    },
});

export const userModeState = atom({
    key: 'userModeState',
    default: getLSItem('userMode') || 'automatic',
});

export const showAuthenticationModalState = atom({
    key: 'showAuthenticationModalState',
    default: null,
});

export const signedInState = atom({
    key: 'signedInState',
    default: !!getLSItem('authorized'),
});

export const modeState = atom({
    key: 'modeState',
    default: 'automatic',
});

export const userRoleState = atom({
    key: 'userRoleState',
    default: null,
});

export const systemModeState = atom({
    key: 'systemModeState',
    default: 'automatic',
});

export const headerModeState = atom({
    key: 'headerModeState',
    default: 'guest',
});

export const searchForceUpdateState = atom({
    key: 'searchForceUpdateState',
    default: '',
});

export const workspaceModeState = atom({
    key: 'workspaceModeState',
    default: !!getLSItem('workspaceMode'),
});

export const isProfileCompleteState = atom({
    key: 'isProfileCompleteState',
    default: false,
});

export const metasState = atom({
    key: 'metasState',
    default: {
        title: whiteLabel?.meta?.title || process.env.REACT_APP_META_TITLE,
        description: whiteLabel?.meta?.description || process.env.REACT_APP_META_DESCRIPTION,
        keywords: whiteLabel?.meta?.keywords || process.env.REACT_APP_META_KEYWORDS,
        key: '',
        enabled: false,
        productRichSnippetScript: '',
        image: {
            source: `${process.env.REACT_APP_HOST}/assets/branding/opengraph.jpg`,
            width: 1200,
            height: 1200,
        }
    },
});

export const wizardState = atom({
    key: 'wizardState',
    default: {
        title: whiteLabel?.meta?.title || process.env.REACT_APP_META_TITLE,
        description: whiteLabel?.meta?.title || process.env.REACT_APP_META_DESCRIPTION,
        keywords: whiteLabel?.meta?.title || process.env.REACT_APP_META_KEYWORDS,
        key: '',
        enabled: false,
        image: {
            source: `${process.env.REACT_APP_HOST}/assets/branding/opengraph.jpg`,
            width: 1200,
            height: 1200,
        }
    },
});

export const loadToasterState = atom({
    key: 'loadToasterState',
    default: false,
});

export const loadGoogleScriptState = atom({
    key: 'loadGoogleScriptState',
    default: false,
});

export const footerVisibleState = atom({
    key: 'footerVisibleState',
    default: false,
});

export const reviewCampaignState = atom({
    key: 'reviewCampaignState',
    default: {
        id: null,
        mode: null,
        active: null,
        callBack: null,
    },
});

export const campaignDetailsState = atom({
    key: 'campaignDetailsState',
    default: {
        id: null,
        mode: null,
        active: null,
    },
});

export const currencyState = atom({
    key: 'currencyState',
    default: '',
});

export const inlineUpSaleOpenState = atom({
    key: 'inlineUpSaleOpenState',
    default: false,
});

export const userState = atom({
    key: 'userState',
    default: null,
});

export const userTmpState = atom({
    key: 'userTmpState',
    default: {
        name: '',
        email: '',
        subscribe: '',
    },
});

export const genericState = atom({
    key: 'genericState',
    default: {},
});

export const favouritesState = atom({
    key: 'favouritesState',
    default: [],
});

export const preciseLocationState = atom({
    key: 'preciseLocationState',
    default: {},
});

export const dummyAtom = atom({
    key: 'dummyAtom',
    default: null,
});

export const dontGoYetModalVisibleState = atom({
    key: 'dontGoYetModalVisibleState',
    default: false,
});

export const languageState = atom({
    key: 'languageState',
    default: null,
});

export const campaignDataState = atom({
    key: 'campaignDataState',
    default: null,
});

export const campaignDataUpdatedAtState = atom({
    key: 'campaignDataUpdatedAtState',
    default: new Date().valueOf(),
});

export const workspaceIdState = atom({
    key: 'workspaceIdState',
    default: '',
});

export const refundOptionsState = atom({
    key: 'refundOptionsState',
    default: {
        hasRefund: false,
        refundFullAmount: false,
    },
});

export const dynamicDataState = atom({
    key: 'dynamicDataState',
    default: {
        messages: {
            count: 0,
        },
        notifications: {
            count: 0,
        },
        deviceStatuses: {},
        workspaceNotifications: {
            count: 0,
        },
    },
});

export const dynamicDataTriggerState = atom({
    key: 'dynamicDataTriggerState',
    default: 0,
});

export const selectedPriceModeState = atom({
    key: 'selectedPriceModeState',
    default: getLSItem('selectedPriceModeState') || 0,
});

export const dynamicDataLoadingState = atom({
    key: 'dynamicDataLoadingState',
    default: true,
});

export const selectedVirtualSlotsState = atom({
    key: 'selectedVirtualSlotsState',
    default: {},
});

export const linkedScreenModalState = atom({
    key: 'linkedScreenModalState',
    default: null,
});

export const addEditScreenModalState = atom({
    key: 'addEditScreenModalState',
    default: {
        mode: 'add',
        title: '',
        active: false,
        isDirty: false,
        primaryButton: {},
    },
});

export const screenPreviewModalState = atom({
    key: 'screenPreviewModalState',
    default: {
        id: 0,
        name: '',
        linked: [],
        comingSoon: false,
        pendingForReview: false,
    },
});

export const myScreensModuleContentCacheState = atom({
    key: 'myScreensModuleContentCacheState',
    default: 0,
});
