import { conditionalSpread, rem } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flex from '../flex';

import useDevice from '../../hooks/useDevice';

import { modeState } from '../../state';

import './index.scss';

const PartnershipFeatures = props => {
    const {
        title,
        cards,
        subTitle,
    } = props;

    const mode = useRecoilValue(modeState);

    const {
        isMobile,
    } = useDevice();

    return (
        <Flex
            column
            gap={isMobile ? 30 : 40}
        >
            <Flex
                column
                gap={24}
                className='partnership-features-head'
            >
                <h2>
                    {title}
                </h2>
                {!!subTitle && (
                    <h3>
                        {subTitle}
                    </h3>
                )}
            </Flex>
            <div className='partnership-features-grid'>
                {cards.map((card, index) => (
                    <Fragment key={index}>
                        <div className='partnership-features-grid-child'>
                            <Flex
                                column
                                gap={isMobile ? 12 : 16}
                                className='partnership-feature-item'
                            >
                                {!!card.graphics?.length && (
                                    <div className='partnership-feature-graphics'>
                                        {card.graphics.map((graphic, index) => (
                                            <div
                                                key={index}
                                                className={classNames(
                                                    'partnership-feature-graphic-item',
                                                    {
                                                        'bubble': !graphic.children,
                                                    }
                                                )}
                                                style={{
                                                    color: graphic.color,
                                                    ...conditionalSpread({
                                                        transform: `translate3d(${(isNaN(graphic.x) ? graphic.x : rem(graphic.x)) || 0}, ${(isNaN(graphic.y) ? graphic.y : rem(graphic.y)) || 0}, 0)`,
                                                    }, graphic.x || graphic.y),
                                                }}
                                            >
                                                {!!graphic.children && (
                                                    <span>{graphic.children}</span>
                                                )}
                                                {graphic.icon}
                                            </div>
                                        ))}
                                        {mode === 'dark' ? card.icon.dark : card.icon.light}
                                    </div>
                                )}
                                <h4>
                                    {card.title}
                                </h4>
                                <p>
                                    {card.children}
                                </p>
                            </Flex>
                        </div>
                        {index === 2 && (
                            <div className='partnership-features-grid-hd' />
                        )}
                    </Fragment>
                ))}
            </div>
        </Flex>
    );
};

PartnershipFeatures.propTypes = {
    title: PropTypes.node,
    cards: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.shape({
            light: PropTypes.node,
            dark: PropTypes.node,
        }),
        title: PropTypes.node,
        graphics: PropTypes.array,
        children: PropTypes.node,
    })),
    subTitle: PropTypes.node,
};

export default PartnershipFeatures;
