import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Flex from '../flex';
import Button from '../button';
import Translate from '../translate';

import useDevice from '../../hooks/useDevice';
import useInlineTranslation from '../../hooks/useInlineTranslation';

import { iconSize, supportEmail } from '../../helpers';

import './index.scss';

const PartnershipUpSaleRow = props => {
    const {
        cta,
        type,
    } = props;

    const { getInlineTranslation } = useInlineTranslation();

    const {
        isMobile,
    } = useDevice();

    return (
        <>
            <div
                className={classNames(
                    'partnership-up-sale-row-holder',
                    `t-${type}`,
                )}
            >
                {(() => {
                    switch (type) {
                        case 'billboards':
                            return (
                                <svg style={iconSize(804, 881)} viewBox='0 0 568 522' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path strokeWidth='0.5' stroke='var(--theme-color)' d='M195.1,216.6h0c-3.7,11-7.3,23.2-10.7,36.5-7.3,27.8-12.3,55.2-15,82.1-2.8,27.1-3.4,53.4-1.7,79,.3,5.6,2,9.6,5.3,11.8h0c3.3,2.2,6.7,3.1,10.2,2.7,3.9-.4,7-2.3,9-5.6,2-3.3,2.8-7.6,2.5-12.8-1.4-25.1-.9-50.1,1.7-75,2.7-24.8,7.4-49.9,14.2-75.2,2.9-10.9,6.2-21.6,9.8-32.1,3.6-10.5,7.2-20,10.7-28.5h0c1.9-4.5,2.3-8.5,1.2-12-.9-3.4-3.1-5.9-6.5-7.5-3.5-1.8-7.1-2.1-10.7-.9h0c-3.5,1.2-6.3,4.4-8.5,9.3-3.7,7.8-7.5,17.2-11.4,28.2Z' />
                                    <path strokeWidth='0.5' stroke='var(--theme-color)' d='M84.6,143.3h0c-3.3-1.4-6.6-1.4-10,0-3.6,1.4-6.3,4.5-8.2,9.2-2.7,6.3-4.9,13.4-6.8,21-2,7.6-3.5,15-4.5,22.1h0c-.6,4.7.2,8.5,2.4,11.3,2.1,2.9,5,4.6,8.8,5.3h0s0,0,0,0c3.4.4,6.4-.4,9.2-2.5,2.8-2.1,4.4-5.8,5.1-10.7h0c1.7-12.9,5-25.4,9.8-37.3,1.8-4.4,2.1-8.3.9-11.6h0,0c-1.3-3.2-3.5-5.5-6.7-6.8Z' />
                                    <path strokeWidth='0.5' stroke='var(--theme-color)' d='M129,184.9h0c-4.9,12.3-9.3,25.5-13.3,39.7-3.9,14.1-7,28.6-9.2,43.4-2.3,14.7-3.3,29.2-3,43.4-.1,4.7,1.3,8.4,4.3,11.1h0c3.1,2.6,6.5,3.9,10.4,3.8h0s0,0,0,0c4-.2,7.2-1.9,9.3-5,2.3-3,3.4-7.1,3.5-12.1h0c.1-18.3,2-37.3,5.7-57.1,3.8-19.6,9.4-38.3,16.7-56.1,1.9-4.5,2.2-8.6,1.1-12.3-1.2-3.6-3.5-6.2-7-7.6h0c-3.7-1.5-7.2-1.6-10.6-.3-3.4,1.3-6,4.4-8,9.3Z' />
                                    <path strokeWidth='0.5' stroke='var(--theme-color)' d='M271.8,207.7h0c-5.9,16.8-11,33.1-15.3,48.9-8.8,33-14.6,64.8-17.4,95.4-2.8,30.8-2.7,58.1.4,81.9h0c.6,4.4,2.4,7.9,5.4,10.4h0c3.2,2.5,6.8,3.3,10.8,2.4h0c3.9-.9,6.7-3.1,8.2-6.5,1.7-3.2,2.3-7.2,1.8-12h0c-2.3-21.8-2.1-47.1.7-75.7,2.7-28.6,8.2-58.2,16.4-88.8,4.1-15.2,8.9-30.8,14.4-46.7,5.5-15.9,11.7-31.4,18.4-46.3,6.9-14.9,14.1-28.5,21.7-40.6h0c2.4-3.9,3.3-7.9,2.9-11.8-.3-3.8-2.3-6.8-5.8-9.1h0s0,0,0,0c-3.4-1.9-7.1-2.4-10.8-1.4-3.8,1-6.9,3.7-9.3,7.9-8,13.2-15.6,27.6-22.9,43.3h0c-7.1,15.8-13.6,32.1-19.5,48.7Z' />
                                    <path strokeWidth='0.5' stroke='var(--theme-color)' d='M359.6,481.1c-14.5,0-26.7-8.6-34.2-24.3-17.8-37.2-10-112.5,3.1-170.6,17.5-76.8,46.6-139.5,77.9-167.6,23.6-21.2,50-24.6,74.1-9.6,24,14.9,39.9,41.8,47.3,80,5.6,28.6,6,63.6,1.3,98.5-1.1,7.9-8.4,13.5-16.3,12.4-7.9-1.1-13.5-8.4-12.4-16.3,7.4-54.5,2.6-126.5-35.2-150-16.9-10.5-30-2-39.5,6.6-26.6,23.9-53.1,82.3-69,152.4-15,66-17.1,126.9-5.3,151.7,4.1,8.5,7.4,8,9.6,7.6,6.5-1.1,13.7-7,21-17.2,15.4-21.8,29.4-61.2,38.4-108.3,7.9-41.1,10.6-82.9,6.9-104-1.4-7.9,3.8-15.4,11.7-16.8,7.9-1.4,15.4,3.8,16.8,11.7,4.5,25,1.8,68.9-6.9,114.6-5.7,29.9-19,85.3-43.2,119.6-12,16.9-25.4,26.7-40,29.1-2.1.3-4.2.5-6.2.5Z' />
                                </svg>
                            );
                        case 'agencies':
                            return (
                                <>
                                    <svg style={iconSize(548, 548)} viewBox='0 0 548 548' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M298.629 173.704L298.349 174.235L298.629 173.704L298.573 173.674C272.017 159.688 252.037 149.166 236.216 143.578C220.377 137.985 208.553 137.281 198.404 143.14C188.255 149 182.952 159.592 179.877 176.106C176.805 192.602 175.928 215.17 174.763 245.166L174.761 245.219L172.03 315.491L172.028 315.552C170.362 358.428 169.11 390.657 171.592 414.287C174.076 437.93 180.314 453.148 193.797 461.798C207.289 470.454 223.173 469.414 244.594 461.095C265.997 452.783 293.1 437.135 329.152 416.321L329.204 416.29L369.558 392.992L369.611 392.962C405.662 372.147 432.766 356.499 450.666 342.12C468.58 327.728 477.423 314.492 476.673 298.479C475.923 282.478 465.863 269.466 446.63 255.494C427.405 241.528 398.867 226.498 360.898 206.501L360.85 206.475L298.629 173.704Z' stroke='var(--theme-color)' strokeWidth='0.7' />
                                        <path d='M170.04 391.731L427.87 242.872M173.848 273.771L323.81 187.19' stroke='var(--theme-color)' strokeWidth='0.7' />
                                    </svg>
                                    <svg style={iconSize(568, 568)} viewBox='0 0 568 568' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <g clipPath='url(#clip0_3303_4964)'>
                                            <path d='M386.767 282.089L386.181 281.956L386.767 282.089L386.781 282.027C393.417 252.755 398.41 230.733 399.712 214.005C401.015 197.258 398.635 185.655 390.348 177.368C382.062 169.081 370.458 166.701 353.711 168.005C336.982 169.307 314.956 174.301 285.681 180.939L285.628 180.95L217.045 196.5L216.985 196.514C175.138 206.002 143.684 213.134 121.502 221.648C99.307 230.166 86.2225 240.13 81.3569 255.393C76.488 270.666 81.6031 285.739 95.1824 304.277C108.751 322.799 130.881 344.929 160.316 374.365L160.359 374.408L193.308 407.357L193.351 407.4C222.787 436.836 244.917 458.966 263.44 472.534C281.977 486.113 297.051 491.229 312.324 486.36C327.586 481.494 337.551 468.41 346.069 446.215C354.583 424.031 361.715 392.575 371.204 350.724L371.216 350.671L386.767 282.089Z' stroke='var(--theme-color)' strokeWidth='0.7' />
                                            <path d='M142.886 214.311L353.404 424.828M257.812 187.459L380.256 309.903' stroke='var(--theme-color)' strokeWidth='0.7' />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_3303_4964'>
                                                <rect width='401.007' height='401.007' fill='white' transform='translate(283.588 0.570312) rotate(45)' />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </>
                            );
                        default:
                            return null;
                    }
                })()}
                <ul className='position-relative'>
                    <li>
                        <h3>
                            {type === 'billboards' ? getInlineTranslation({
                                en: (
                                    <>
                                        Join the<br />
                                        Advelit Partner’s<br />
                                        community today!
                                    </>
                                ),
                                ru: (
                                    <>
                                        Начните<br />
                                        Партнерство с<br />
                                        Advelit сегодня
                                    </>
                                ),
                                hy: (
                                    <>
                                        Սկսե՛ք<br />
                                        համագործակցել<br />
                                        Ադվելիթի հետ այսօր
                                    </>
                                ),
                                ka: (
                                    <>
                                        შეუერთდი<br />
                                        Advelit პარტნიორის<br />
                                        საზოგადოება დღეს!
                                    </>
                                ),
                            }) : getInlineTranslation({
                                en: (
                                    <>
                                        Launch Your First<br />
                                        OOH Campaign Now!
                                    </>
                                ),
                                ru: (
                                    <>
                                        Разместите<br />
                                        рекламу за 5 шагов
                                    </>
                                ),
                                hy: (
                                    <>
                                        Գովազդի՛ր<br />
                                        ընդամենը 5 քայլով
                                    </>
                                ),
                                ka: (
                                    <>
                                        გაუშვით თქვენი<br />
                                        პირველი რეკლამა ახლავე!
                                    </>
                                ),
                            })}
                        </h3>
                    </li>
                    <li>
                        <div className='partnership-up-sale-row-cta'>
                            {type === 'billboards' ? (
                                <Button
                                    color='bold-invert'
                                    size={isMobile ? 'extra-big' : 'extra-huge'}
                                    flexibility={isMobile ? 'full' : 'fit'}
                                    itemsDirection='end'
                                    icon={{
                                        type: 'icon-a-arrow-right',
                                    }}
                                    {...(cta || {})}
                                >
                                    {cta?.children || (
                                        <Translate>Become a Partner Now</Translate>
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    to='/s'
                                    color='bold'
                                    size={isMobile ? 'extra-big' : 'extra-huge'}
                                    flexibility={isMobile ? 'full' : 'fit'}
                                    itemsDirection='end'
                                    icon={{
                                        type: 'icon-a-arrow-right',
                                    }}
                                    {...(cta || {})}
                                >
                                    <Translate>Create Campaign</Translate>
                                </Button>
                            )}
                            <Flex
                                gap={isMobile ? 8 : 16}
                                justify={isMobile ? 'center' : 'flex-start'}
                            >
                                <p>
                                    <Translate>Have questions?</Translate>
                                </p>
                                <a
                                    href='tel:+37433084455'
                                    className='link-effect'
                                >
                                    <Translate>Call</Translate>
                                </a>
                                <a
                                    href={`mailto:${supportEmail}`}
                                    className='link-effect'
                                >
                                    <Translate>Email</Translate>
                                </a>
                            </Flex>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

PartnershipUpSaleRow.propTypes = {
    cta: PropTypes.object,
    type: PropTypes.oneOf([
        'billboards',
        'agencies',
    ]),
};

export default PartnershipUpSaleRow;
